import React, { useState, useContext } from "react";
import { Row, Col, Card, Carousel, Table } from "react-bootstrap";
import { DarkModeContext } from "../../context/DarkModeContext";
import "./ContenidoInicio.css";
import Chart from 'chart.js/auto'; // Importar Chart de chart.js para registrar plugins
import { Pie } from 'react-chartjs-2';
import Select from 'react-select';


const ContenidoInicio = ({ resultadosPorMes }) => {
  const { darkMode } = useContext(DarkModeContext);
  const [isPaused, setIsPaused] = useState(false);
 const [mesSeleccionado, setMesSeleccionado] = useState(null);

  const ultimoResultado = resultadosPorMes.length > 0 ? resultadosPorMes[resultadosPorMes.length - 2] : null;
  const nivelUsuario = ultimoResultado ? ultimoResultado.nivel : 0;
  const porcentaje = ultimoResultado ? ultimoResultado.porcentaje : 0;
  const comision = ultimoResultado ? ultimoResultado.comision : 0;
  const nombreComercio= ultimoResultado? ultimoResultado.nombre: "";
  const nivel0= ultimoResultado? ultimoResultado.nivel0:0;
  const nivel1= ultimoResultado? ultimoResultado.nivel1:0;
  const nivel2= ultimoResultado? ultimoResultado.nivel2:0;
  const nivel3= ultimoResultado? ultimoResultado.nivel3:0
  const comision1= ultimoResultado?  ultimoResultado.comisionNivel1:0;
  const comision2= ultimoResultado?  ultimoResultado.comisionNivel2:0;
  const comision3= ultimoResultado?  ultimoResultado.comisionNivel3:0;
  console.log(resultadosPorMes)
  

  // Agrupar los resultados en conjuntos de 3 sin duplicación
  const groupResults = (results) => {
    const grouped = [];
    for (let i = 0; i < results.length; i += 3) {
      grouped.push(results.slice(i, i + 3));
    }
    return grouped;
  };
  const styles = {
    progressContainer: {
      width: "100%",
      height: "10px",
      backgroundColor: "#ddd",
      borderRadius: "5px",
      // overflow: "hidden",
      marginTop: "5px",
      position: "relative",
    },
    progressBar: {
      height: "100%",
      backgroundColor: "#B4C400",
      transition: "width 0.5s ease-in-out",
      position: "relative",
      zIndex: 1,
    },
    progressText: {
      position: "absolute",
      right: "10px",
      top: "50%",
      transform: "translateY(-50%)",
      fontSize: "10px",
      fontWeight: "bold",
      color: "#fff",
    },
    levelCircle: {
      position: "absolute",
      top: "50%",
      transform: "translate(-50%, -50%)",
      width: "18px",
      height: "18px",
      borderRadius: "50%",
      border: "2px solid #fff",
      textAlign: "center",
      fontSize: "9px",
      fontWeight: "bold",
      lineHeight: "14px",
      color: "#333",
      zIndex: 2,
    },
    progressLevels: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "3px",
      fontSize: "10px",
      fontWeight: "bold",
      color: "#333",
    },
  };
  const groupedResultados = groupResults(resultadosPorMes);

  const handleCardClick = () => {
    setIsPaused(!isPaused);
  };
  const getNivelData = (porcentaje) => {
    return {
      datasets: [
        {
          data: [porcentaje, 100 - porcentaje],
          backgroundColor: [darkMode ? "#f8f9fa" : "#B4C400", "#dcdcdc"],
          borderWidth: 0,
        },
      ],
    };
  };
  const mesOptions = Array.from(
    new Set(resultadosPorMes.map((resultado) => resultado.mes))
  ).map((mes) => ({
    value: mes,
    label: mes,
  }));
  const niveles = [
    { nivel: 0, porcentaje: 0 },
    { nivel: 1, porcentaje: 33.3 },
    { nivel: 2, porcentaje: 66.6 },
    { nivel: 3, porcentaje: 100 },
  ];


  return (
    <div
      className="container"
      style={{ marginTop: "20px", marginBottom: "20px" }}
    >
      <section className="container ">
      <div style={{marginBottom:"5px"}} className="row">
      <article className="col-12 col-md-4 col-lg-4 py-2 d-flex flex-column align-items-center">
     
          <div
            className={
              darkMode
                ? " bg-data-header-dark border-0 quitar-cursor-pointer"
                : "container-light bg-data-header  border-0 quitar-cursor-pointer"
            }
             style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "55px", // Asegúrate de que coincida con la altura del círculo
              }}
          >
            <div className=" d-flex justify-content-center border-0 lato-bold-a-lato-500  fs-14">
               {nombreComercio}
            </div>
            
          </div>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ position: "relative", width: "240px", height: "130px" }}
            >
              <Pie data={getNivelData(porcentaje)} options={{ responsive: true, maintainAspectRatio: false, cutout: "80%"}} />
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: darkMode ? "#f8f9fa" : "#000",
                }}
              >
                Nivel {nivelUsuario}
              </span>
            </div>

           

         
            
          </article>
          <article className="col-12 col-md-8 col-lg-8 py-2">
            <div className="col">
              <div className="row">
                <div className="col">
                <article className="col-12 col-md-6 col-lg-6  py-2">
                <h6 className="fs-14 lato-bold">
                  Comision actual: 
                  {new Intl.NumberFormat("es-AR", {
    style: "currency",
    currency: "ARS",
  }).format(comision)}
              </h6>
              {/* <h6 className="fs-17 lato-bold">
                {nombreComercio}
              </h6> */}
              <h6 className="fs-14 lato-regular">
                Estatus
              </h6>
              
          
        </article>

                </div>
                

              
        
        <article className="col-12 col-md-6 col-lg-6  py-2">
          <div className="d-flex justify-content-end">
          <Select
      className="select__control_custom-inicio select__control_custom lato-bold fs-12"
          classNamePrefix="select"
        options={mesOptions}
        onChange={(selected) => setMesSeleccionado(selected)}
        placeholder="Seleccionar Mes"
        isClearable
      />
          </div>
        
        </article>
                
              </div>
              <div className="row">
              <article className="col-12">
                
                <h6>
                  Facturacion a alcanzar
                </h6>
              <div style={styles.progressLevels}>
                    <span>{nivel0}</span>
                    <span>{nivel1}</span>
                    <span>{nivel2}</span>
                    <span>{nivel3}</span>
                  </div>
                  <div style={styles.progressContainer}>
                    {/* CÍRCULOS DE NIVELES FIJOS EN EL 100% */}
                    {niveles.map(({ nivel, porcentaje: nivelPorcentaje }) => (
                        <span
                          key={nivel}
                          style={{
                            ...styles.levelCircle,
                            left: `${nivelPorcentaje}%`, // SIEMPRE en base al 100%
                            backgroundColor: porcentaje >= nivelPorcentaje ? "#fff" : "#999",
                          }}
                        >
                          {nivel}
                        </span>
                      ))}
                  <div style={{ ...styles.progressBar, width: `${porcentaje}%` }}>

                        
                    </div>
                  </div>
                 
                  <h6>
                  Comision por nivel
                </h6>
                <div style={styles.progressLevels}>
                    <span>0 </span>
                    <span>{comision1}</span>
                    <span>{comision2}</span>
                    <span>{comision3}</span>
                  </div>
                </article>
                
                {/* <article className="col-12 col-md-6 col-lg-3">
                    <button
            className="btn"
           
            style={{
              borderRadius: "23px",
              width: "182px",
              height: "44px",
              background: "#B4C400",
              color: "#FFFFFF",
              marginTop: "10px",
            }}
          >
            Paso 1
          </button>
                </article>
                <article className="col-12 col-md-6 col-lg-3">
                  <button
            className="btn"
           
            style={{
              borderRadius: "23px",
              width: "182px",
              height: "44px",
              background: "#B4C400",
              color: "#FFFFFF",
              marginTop: "10px",
            }}
          >
            Paso 2
          </button>
                </article>
                <article className="col-12 col-md-6 col-lg-3">
                   <button
            className="btn"
           
            style={{
              borderRadius: "23px",
              width: "182px",
              height: "44px",
              background: "#B4C400",
              color: "#FFFFFF",
              marginTop: "10px",
            }}
          >
            Paso 3
          </button>
                </article>
                <article className="col-12 col-md-6 col-lg-3">
                  <button
            className="btn"
           
            style={{
              borderRadius: "23px",
              width: "182px",
              height: "44px",
              background: "#B4C400",
              color: "#FFFFFF",
              marginTop: "10px",
            }}
          >
            Paso 4
          </button>
                </article>
             */}
          
         
          
                
                </div>

            </div>
          </article>
       
        

      </div>
    </section>
    <div>
    <Table table table-borderless responsive striped hover>
        <thead className="bg-dark py-2">
        <tr className="text-center tabla-thead">
                                      
                                        
                                      <th
                                          className={
                                              darkMode
                                                  ? "bg-white text-dark border-tabla-izquierda border-0 lato-regular fs-12 py-3"
                                                  : "bg-dark text-white border-tabla-izquierda border-0 lato-regular fs-12 py-3"
                                          }
                                          scope="col"
                                      >
                                          CUIT Aliado
                                      </th>
                                      <th
                                          className={
                                              darkMode
                                                  ? "bg-white text-dark border-tabla border-0 lato-regular fs-12 py-3"
                                                  : "bg-dark text-white border-tabla border-0 lato-regular fs-12 py-3"
                                          }
                                          scope="col"
                                      >
                                          Nombre Aliado
                                      </th>
                                      <th
                                          className={
                                              darkMode
                                                  ? "bg-white text-dark border-tabla border-0 lato-regular fs-12 py-3"
                                                  : "bg-dark text-white border-tabla border-0 lato-regular fs-12 py-3"
                                          }
                                          scope="col"
                                      >
                                          Comision
                                      </th>
                                      <th
                                          className={
                                              darkMode
                                                  ? "bg-white text-dark border-tabla border-0 lato-regular fs-12 py-3"
                                                  : "bg-dark text-white border-tabla border-0 lato-regular fs-12 py-3"
                                          }
                                          scope="col"
                                      >
                                          Detalle
                                      </th>
                                      <th
                                          className={
                                              darkMode
                                                  ? "bg-white text-dark border-tabla-derecha border-0 lato-regular fs-12 py-3"
                                                  : "bg-dark text-white border-tabla-derecha border-0 lato-regular fs-12 py-3"
                                          }
                                          scope="col"
                                      >
                                          Comprobante
                                      </th>

                                  </tr>

        </thead>
        <tbody className="text-center">
          {
            resultadosPorMes.length > 0 ? (
              resultadosPorMes.map((dato, index) => (
                <tr  className={
                  darkMode ? "bg-dark text-white" : "bg-white text-dark"
              }
             > 
              <td
                  
                  className={
                      darkMode
                          ? "fs-10 lato-bold py-4 prority-4 text-white "
                          : "fs-10 lato-bold py-4 prority-4 "
                  }
              >
                {dato.cuit}
                  
              </td>
              
              <td
              className={
                  darkMode
                      ? "fs-10 lato-bold py-4 prority-4 text-white "
                      : "fs-10 lato-bold py-4 prority-4 "
              }
          >
            {dato.nombre}

          </td>
         
              
              
              <td
                  
                  className={
                      darkMode
                          ? "fs-10 lato-bold py-4 prority-4 text-white "
                          : "fs-10 lato-bold py-4 prority-4 "
                  }
              >
               {new Intl.NumberFormat("es-AR", {
    style: "currency",
    currency: "ARS",
  }).format(dato.totalBruto)}
              </td>
              <td
                  
                  className={
                      darkMode
                          ? "fs-10 lato-bold py-4 prority-4 text-white "
                          : "fs-10 lato-bold py-4 prority-4 "
                  }
              >
                {dato.mes}
              </td>
              <td
                  
                  className={
                      darkMode
                          ? "fs-10 lato-bold py-4 prority-4 text-white "
                          : "fs-10 lato-bold py-4 prority-4 "
                  }
              >
              </td>

</tr>
              ))
            ):(
                <td>No hay datos para mostrar</td>
            )
              
          }
         

        </tbody>

      </Table>


    </div>
      
      {/* <Carousel
        controls={false}
        indicators={false}
        pause={isPaused ? "hover" : false}
        interval={isPaused ? null : 3000}
      >
        {groupedResultados.map((grupo, index) => (
          <Carousel.Item key={index}>
            <Row className="custom-row d-flex justify-content-center">
              {grupo.map((resultado, idx) => (
                <Col
                  key={idx}
                  xs={12}
                  md={4}
                  className="d-flex justify-content-center"
                >
                  <Card
                    className="h-100 card"
                    style={{
                      borderRadius: "28px",
                      cursor: "pointer",
                      width: "18rem",
                    }}
                    onClick={handleCardClick}
                  >
                    <Card.Body>
                      <Card.Title
                        className={
                          darkMode
                            ? "fs-17 lato-bold py-4 prority-4 text-white"
                            : "fs-17 lato-bold py-4 prority-4"
                        }
                      >
                        CUIT/CUIL: {resultado.cuit}
                      </Card.Title>
                      <Card.Text>
                        <strong
                          className={
                            darkMode
                              ? "fs-14 lato-regular py-4 prority-4 text-white"
                              : "fs-14 lato-regular py-4 prority-4"
                          }
                        >
                          Nombre:{" "}
                        </strong>{" "}
                        {resultado.nombre} <br />
                        <strong
                          className={
                            darkMode
                              ? "fs-14 lato-regular py-4 prority-4 text-white"
                              : "fs-14 lato-regular py-4 prority-4"
                          }
                        >
                          Mes:{" "}
                        </strong>{" "}
                        {resultado.mes} <br />
                        <strong
                          className={
                            darkMode
                              ? "fs-14 lato-regular py-4 prority-4 text-white"
                              : "fs-14 lato-regular py-4 prority-4"
                          }
                        >
                          Total Bruto:{" "}
                        </strong>{" "}
                        {resultado.totalBruto} <br />
                        <strong
                          className={
                            darkMode
                              ? "fs-14 lato-regular py-4 prority-4 text-white"
                              : "fs-14 lato-regular py-4 prority-4"
                          }
                        >
                          Comision:{" "}
                        </strong>{" "}
                        {resultado.comision} <br />
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Carousel.Item>
        ))}
      </Carousel> */}
    </div>
  );
};

export default ContenidoInicio;
